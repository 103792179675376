<template>
	<div class="governmentss">
		<div class="container">
			<div class="header">
				<h3>科汇云服务数据云平台</h3>
				<p>{{ nowDate }}{{ nowTime }}</p>
			</div>
			<div class="main">
				<div class="mainCon">
					<div class="box leftCon" style="width: 25%;">
						<!-- 左一 -->
						<div class="tabs tabsbga">
							<h3>科技成果占比</h3>
							<div class="echartsBox" id="echarts1"></div>
						</div>
						<!-- 左二 -->
						<div class="tabs tabsbgb two" >
							<h3 style="margin-bottom: 15px;">专家人才库</h3>
							<ul class="tabTit">
								<li>
									<span>姓名</span>
									<span>学历</span>
									<span>领域类别</span>
									<span>联系方式</span>
								</li>
							</ul>
							<vue-seamless-scroll :data="echarts2" :class-option="optionHover" class="echartsBox"
								style="height: 165px; overflow: hidden;">
								<ul class="item">
									<li v-for="item in echarts2">
										<span>{{ item.name }}</span>
										<span>{{ item.education }}</span>
										<span>{{ item.ptype }}</span>
										<span>{{ item.number }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
						<!-- 左三 -->
						<div class="tabs tabsbgc" >
							<h3>专家领域分布</h3>
							<div class="echartsBox" id="echarts3"></div>
						</div>
					</div>
					<div class="box centerCon" style="width: 45%;">
						<!-- 中一 -->
						<div class="tabs tabsbgd">
							<div class="leftPart">
								<h2>
									新增企业
									<i>(单位：户)</i>
								</h2>
								<p v-html="totalNum"></p>
							</div>
							<div class="rightPart">
								<div class="echartsBox" id="echarts4"></div>
							</div>
						</div>
						<!-- 中二 -->
						<div class="tabs tabsbge" >
							<h3>成果与技术需求</h3>
							<div class="echartsBox" id="echarts5"></div>
						</div>
						<!-- 中三 -->
						<div class="tabs three tabsbgf">
							<h3>技术合同登记（单位：千万）</h3>
							<div class="echartsBox" id="echarts6"></div>
						</div>
					</div>
					<div class="box rightCon" style="width: 25%;">
						<!-- 右一 -->
						<div class="tabs two tabsbgb">
							<h3>服务资源库</h3>
							<ul class="tabTit">
								<li>
									<span style="width: 28%;">公司名称</span>
									<span style="width: 20%;">服务类别</span>
									<span style="width: 38%;">已服务企业</span>
								</li>
							</ul>
							<vue-seamless-scroll :data="echarts7" :class-option="optionHover" class="echartsBox"
								style="height: 180px; overflow: hidden;">
								<ul class="item">
									<li v-for="item in echarts7">
										<span style="width: 28%;">{{ item.name }}</span>
										<span style="width: 20%;">{{ item.ptype }}</span>
										<span style="width: 38%;">{{ item.number }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
						<!-- 右二 -->
						<div class="tabs two tabsbgb" >
							<h3>技术需求库</h3>
							<ul class="tabTit">
								<li>
									<span style="width: 28%;">技术需求名称</span>
									<span style="width: 20%;">所属类别</span>
									<span style="width: 38%;">发布金额</span>
								</li>
							</ul>
							<vue-seamless-scroll :data="echarts8" :class-option="optionHover" class="echartsBox"
								style="height: 186px; overflow: hidden;">
								<ul class="item">
									<li v-for="item in echarts8">
										<span style="width: 28%;">{{ item.name }}</span>
										<span style="width: 20%;">{{ item.ptype }}</span>
										<span style="width: 38%;">{{ item.number }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
						<!-- 右三 -->
						<div class="tabs two tabsbgg" >
							<h3>入驻企业分布</h3>
							<div class="echartsBox" id="echarts9"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		kjlist,
		zonghe,
		zjlist,
		qylists,
		chju,
		contractlist,
		servicek,
		jushuk,
		qylist
	} from '../../../common/js/api';
	export default {
		name: 'enterpriseCloud',
		components: {},
		data() {
			return {
				totals: 0,
				nowDate: null, //存放年月日变量
				nowTime: null, //存放时分秒变量
				timer: '', //定义一个定时器的变量
				currentTime: new Date(), // 获取当前时间
				echarts2: [],
				totalNum: 0,
				echarts7: [],
				echarts8: []
			};
		},
		created() {
			// 获取时间
			this.timer = setInterval(this.getTime, 1000);
			// 科技成果占比
			this.kjlist();
			// 专家人才库
			this.zonghe();
			// 专家领域发布
			this.zjlist();
			// 新增企业
			this.qylists();
			// 成果与技术需求
			this.chju();
			// 技术合同登记
			this.contractlist();
			// 服务资源库
			this.servicek();
			// 技术需求库
			this.jushuk();
			// 入驻企业分布
			this.qylist();
		},
		computed: {
			optionHover() {
				return {
					step: 0.3, //速度，值越大，速度越快
					hoverStop: true, //鼠标悬停效果，false为关闭该效果
					// singleHeight: 26, //单行停顿
					// waitTime: 2500 //单行停顿的时间
					limitMoveNum: 1 // 开始无缝滚动的数据量
				};
			}
		},
		mounted() {},
		methods: {
			// 获取时间
			getTime() {
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const hour = date.getHours();
				const minute = date.getMinutes();
				const second = date.getSeconds();
				const str = '';
				if (this.hour > 12) {
					this.hour -= 12;
					// this.str = ' PM';
					this.str = ' ';
				} else {
					// this.str = ' AM';
					this.str = ' ';
				}
				this.month = check(month);
				this.day = check(day);
				this.hour = check(hour);
				this.minute = check(minute);
				this.second = check(second);

				function check(i) {
					const num = i < 10 ? '0' + i : i;
					return num;
				}
				this.nowDate = year + '年' + this.month + '月' + this.day + '日';
				this.nowTime = this.hour + '时' + this.minute + '分' + this.second + '秒' + this.str;
			},
			// 科技成果占比 - data
			kjlist() {
				var data = {
					name: [],
					num: [],
					nums: []
				};
				kjlist()
					.then(res => {
						res.data.map(item => {
							data.name.push(item.name);
							data.num.push(Number(item.num));
							data.nums.push(100);
						});
						data = data;
						this.$nextTick(function() {
							this.echarts1(data);
						});
					})
					.catch(() => {
						data = [];
					});
			},
			// 科技成果占比 - 图表
			echarts1(data) {
				let echarts1 = this.$echarts.init(document.getElementById('echarts1'));
				echarts1.setOption({
					tooltip: {
						show: false
					},
					grid: {
						top: '0%',
						left: '0',
						right: '5%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: {
						min: 0,
						max: 100,
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {
							show: false
						}
					},
					yAxis: {
						data: data.name.reverse(),
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {
							color: '#ffffff',
							fontSize: 12
						}
					},
					series: [{
							type: 'bar',
							label: {
								show: true,
								zlevel: 10000,
								position: 'right',
								borderRadius: '30',
								padding: 5,
								color: '#49bcf7',
								fontSize: 12,
								formatter: '{c}%'
							},
							itemStyle: {
								color: {
								    type: 'linear',
								    x: 0,
								    y: 0,
								    x2: 1,
								    y2: 0,
								    colorStops: [{
								      offset: 0,
								      color: '#004ad0'
								    }, {
								      offset: 0.5,
								      color: '#1670e6'
								    }, {
								      offset: 1,
								      color: '#2f9afe'
								    }]
								  }
							},
							barWidth: '20',
							data: data.num.reverse(),
							z: 10
						},
						{
							type: 'bar',
							barGap: '-100%',
							itemStyle: {
								color: '#fff',
								opacity: 0
							},
							barWidth: '0',
							data: data.nums,
							z: 5
						}
					]
				});
				window.addEventListener('resize', function() {
					echarts1.resize();
				});
			},
			// 专家人才库
			zonghe() {
				zonghe()
					.then(res => {
						this.echarts2 = res.data;
					})
					.catch(() => {
						this.echarts2 = [];
					});
			},
			// 专家领域发布 - data
			zjlist() {
				var data = {
					name: [],
					value: []
				};
				zjlist()
					.then(res => {
						res.data.map(item => {
							data.name.push(item.name);
							data.value.push({
								value: Number(item.num),
								name: item.name
							});
						});
						data = data;
						this.$nextTick(function() {
							this.echarts3(data);
						});
					})
					.catch(() => {
						data = [];
					});
			},
			// 专家领域发布 - 图表
			echarts3(data) {
				let echarts3 = this.$echarts.init(document.getElementById('echarts3'));
				echarts3.setOption({
					tooltip: {
						trigger: 'item',
						formatter: '{b} : {c} ({d}%)',
						textStyle: {
							lineHeight: 50
						}
					},
					legend: {
						right: 0,
						top: 20,
						height: 160,
						itemWidth: 20,
						itemHeight: 10,
						itemGap: 10,
						textStyle: {
							color: 'rgba(255,255,255,1)',
							fontSize: 12,
							lineHeight: 18
						},
						orient: 'vertical',
						data: data.name
					},
					calculable: true,
					series: [{
						 name: 'Nightingale Chart',
						type: 'pie',
						radius: ['15%', '70%'],
						center: ['35%', '45%'],
						roseType: 'area',
						color: ['#fb497b', '#fec760', '#70e622', '#4eccff', '#4d7bf3', '#1f4b9a'],
						label: {
							normal: {
								show: true
							},
							emphasis: {
								show: true
							}
						},
						labelLine: {
							show: true,
							length: 5,
							length2: 5
						},
						data: data.value
					}]
				});
				window.addEventListener('resize', function() {
					echarts3.resize();
				});
			},
			// 新增企业 - data
			qylists() {
				this.totalNum = 0;
				qylists()
					.then(res => {
						res.data.map(item => {
							this.totalNum += Math.floor(Math.round(item.num * 100) / 100);
						});
						this.$nextTick(function() {
							this.echarts4(res.data, this.totalNum);
						});
					})
					.catch(() => {
						res.data = [];
					});
			},
			// 新增企业 - 图表
			echarts4(data, totalNum) {
				let echarts3 = this.$echarts.init(document.getElementById('echarts4'));
				echarts3.setOption({
					title: [{
							subtext: '电子信息',
							top: '20',
							left: '21%',
							textAlign: 'center',
							subtextStyle: {
								fontSize: 16,
								color: '#fff'
							}
						},
						{
							subtext: '生物医药',
							top: '20',
							left: '53%',
							textAlign: 'center',
							subtextStyle: {
								fontSize: 16,
								color: '#fff'
							}
						},
						{
							subtext: '装备制造',
							top: '20',
							left: '83.33%',
							textAlign: 'center',
							subtextStyle: {
								fontSize: 16,
								color: '#fff'
							}
						}
					],
					series: [{
							type: 'pie',
							radius: ['72%', '84%'],
							label: {
								normal: {
									position: 'center'
								}
							},
							left: 0,
							right: '55%',
							top: '30%',
							bottom: 0,
							data: [{
									value: data[0].num,
									label: {
										formatter: function(params) {
											return `{a|${data[0].num}}\n{b|占比${Math.round((data[0].num / totalNum) * 100)}%}`;
										},
										rich: {
											a: {
												fontSize: 18,
												color: '#fff',
												lineHeight: 20
											},
											b: {
												fontSize: 12,
												color: '#bbb',
												lineHeight: 24
											}
										}
									},
									itemStyle: {
										normal: {
											color: '#49bcf7'
										},
										emphasis: {
											color: '#49bcf7'
										}
									}
								},
								{
									value: data[0].num,
									label: {},
									itemStyle: {
										normal: {
											color: 'rgba(255,255,255,.2)'
										},
										emphasis: {
											color: 'rgba(255,255,255,.2)'
										}
									}
								}
							]
						},
						{
							type: 'pie',
							radius: ['72%', '84%'],
							label: {
								normal: {
									position: 'center'
								}
							},
							left: '38%',
							right: '30%',
							top: '30%',
							bottom: 0,
							data: [{
									value: data[1].num,
									label: {
										formatter: function(params) {
											return `{a|${data[1].num}}\n{b|占比${Math.round((data[1].num / totalNum) * 100)}%}`;
										},
										rich: {
											a: {
												fontSize: 18,
												color: '#fff',
												lineHeight: 20
											},
											b: {
												fontSize: 12,
												color: '#bbb',
												lineHeight: 24
											}
										}
									},
									itemStyle: {
										normal: {
											color: '#cdba00'
										},
										emphasis: {
											color: '#cdba00'
										}
									}
								},
								{
									value: data[1].num,
									label: {},
									itemStyle: {
										normal: {
											color: 'rgba(255,255,255,.2)'
										},
										emphasis: {
											color: 'rgba(255,255,255,.2)'
										}
									}
								}
							]
						},
						{
							type: 'pie',
							radius: ['72%', '84%'],
							label: {
								normal: {
									position: 'center'
								}
							},
							left: '70%',
							right: 0,
							top: '30%',
							bottom: 0,
							data: [{
									value: data[2].num,
									label: {
										formatter: function(params) {
											return `{a|${data[2].num}}\n{b|占比${Math.round((data[2].num / totalNum) * 100)}%}`;
										},
										rich: {
											a: {
												fontSize: 18,
												color: '#fff',
												lineHeight: 20
											},
											b: {
												fontSize: 12,
												color: '#bbb',
												lineHeight: 24
											}
										}
									},
									itemStyle: {
										normal: {
											color: '#62c98d'
										},
										emphasis: {
											color: '#62c98d'
										}
									}
								},
								{
									value: data[2].num,
									label: {},
									itemStyle: {
										normal: {
											color: 'rgba(255,255,255,.2)'
										},
										emphasis: {
											color: 'rgba(255,255,255,.2)'
										}
									}
								}
							]
						}
					]
				});
				window.addEventListener('resize', function() {
					echarts3.resize();
				});
			},
			// 成果与技术需求 - data
			chju() {
				var data = {
					data1: [],
					data2: [],
					data3: []
				};
				chju()
					.then(res => {
						res.data.map(item => {
							data.data1.push(Number(item.one));
							data.data2.push(Number(item.ones));
							data.data3.push(Number(Math.round((item.ones / item.one) * 100) / 100) * 100);
						});
						this.$nextTick(function() {
							this.echarts5(data);
						});
					})
					.catch(() => {});
			},
			// 成果与技术需求 - 图表
			echarts5(data) {
				let echarts3 = this.$echarts.init(document.getElementById('echarts5'));
				echarts3.setOption({
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							lineStyle: {
								color: 'rgba(0,0,0,0)'
							}
						}
					},
					legend: {
						data: [{
								name: '科技成果'
							},
							{
								name: '技术需求'
							},
							{
								name: '完成率'
							}
						],
						top: '0%',
						textStyle: {
							color: '0a3f7c'
						}
					},
					xAxis: [{
						type: 'category',
						data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
						axisLine: {
							lineStyle: {
								color: '#01a3e1'
							}
						},
						axisLabel: {
							textStyle: {
								color: '#01a3e1',
								fontSize: '12'
							}
						}
					}],
					yAxis: [{
							type: 'value',
							name: '金额',
							// min: 0,
							// max: 50,
							// interval: 10,
							axisLabel: {
								show: true
							},
							axisLine: {
								lineStyle: {
									color: '#01a3e1'
								}
							}
						},
						{
							type: 'value',
							name: '完成率',
							show: true,
							axisLabel: {
								show: true
							},
							axisLine: {
								lineStyle: {
									color: '#01a3e1'
								}
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: '#01a3e1'
								}
							}
						}
					],
					grid: {
						top: '10%',
						right: '30',
						bottom: '30',
						left: '30'
					},
					series: [{
							name: '科技成果',
							type: 'bar',
							data: data.data1,
							barWidth: 'auto',
							itemStyle: {
								normal: {
									color: {
										type: 'linear',
										x: 0,
										y: 0,
										x2: 0,
										y2: 1,
										colorStops: [{
												offset: 0,
												color: '#3078fe'
											},

											{
												offset: 1,
												color: '#0149d0'
											}
										],
										globalCoord: false
									}
								}
							}
						},
						{
							name: '技术需求',
							type: 'bar',
							data: data.data2,
							barWidth: 'auto',
							itemStyle: {
								normal: {
									color: {
										type: 'linear',
										x: 0,
										y: 0,
										x2: 0,
										y2: 1,
										colorStops: [{
												offset: 0,
												color: '#01df8b'
											},
											{
												offset: 1,
												color: '#009f63'
											}
										],
										globalCoord: false
									}
								}
							},
							barGap: '0'
						},
						{
							name: '完成率',
							type: 'line',
							yAxisIndex: 1,
							data: data.data3,
							lineStyle: {
								normal: {
									width: 2
								}
							},
							itemStyle: {
								normal: {
									color: '#d6dc2f'
								}
							},
							smooth: true
						}
					]
				});
				window.addEventListener('resize', function() {
					echarts3.resize();
				});
			},
			// 技术合同登记 - data
			contractlist() {
				var data = {
					data1: [],
					data2: []
				};
				contractlist()
					.then(res => {
						res.data.map(item => {
							data.data1.push(Number(item.one));
							data.data2.push(Number(item.ones));
						});
						this.$nextTick(function() {
							this.echarts6(data);
						});
					})
					.catch(() => {
						data = [];
					});
			},
			// 技术合同登记 - 图表
			echarts6(data) {
				let echarts3 = this.$echarts.init(document.getElementById('echarts6'));
				echarts3.setOption({
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							lineStyle: {
								color: '#57617B'
							}
						}
					},
					legend: {
						data: ['去年', '今年'],
						top: '0',
						textStyle: {
							color: '#fff'
						},
						itemGap: 20
					},
					grid: {
						left: '0',
						right: '20',
						top: '10',
						bottom: '20',
						containLabel: true
					},
					xAxis: [{
							type: 'category',
							boundaryGap: false,
							axisLabel: {
								show: true,
								textStyle: {
									color: 'rgba(255,255,255,.6)'
								}
							},
							axisLine: {
								lineStyle: {
									color: 'rgba(255,255,255,.1)'
								}
							},
							data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
						},
						{}
					],
					yAxis: [{
						axisLabel: {
							show: true,
							textStyle: {
								color: 'rgba(255,255,255,.6)'
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						}
					}],
					series: [{
							name: '去年',
							type: 'line',
							smooth: true,
							symbol: 'circle',
							symbolSize: 5,
							showSymbol: false,
							lineStyle: {
								normal: {
									width: 2
								}
							},
							areaStyle: {
								normal: {
									color: this.$echarts.graphic.LinearGradient(
										0,
										0,
										0,
										1,
										[{
												offset: 0,
												color: 'rgba(44, 141, 236, 0.3)'
											},
											{
												offset: 0.8,
												color: 'rgba(44, 141, 236, 0.3)'
											}
										],
										false
									),
									shadowColor: 'rgba(0, 0, 0, 0.1)',
									shadowBlur: 10
								}
							},
							itemStyle: {
								normal: {
									color: '#2c8dec',
									borderColor: 'rgba(44, 141, 236, 0.3)',
									borderWidth: 12
								}
							},
							data: data.data1
						},
						{
							name: '今年',
							type: 'line',
							smooth: true,
							symbol: 'circle',
							symbolSize: 5,
							showSymbol: false,
							lineStyle: {
								normal: {
									width: 2
								}
							},
							
							itemStyle: {
								normal: {
									color: '#c7337e',
									borderColor: 'rgba(0,136,212,0.2)',
									borderWidth: 12
								}
							},
							data: data.data2
						}
					]
				});
				window.addEventListener('resize', function() {
					echarts3.resize();
				});
			},
			// 服务资源库
			servicek() {
				servicek()
					.then(res => {
						this.echarts7 = res.data;
						this.totals = 0;
						for (var i = 0; i < res.data.length; i++) {
							this.totals += Number(res.data[i].number);
						}
					})
					.catch(() => {
						this.echarts7 = [];
					});
			},
			// 技术需求库
			jushuk() {
				jushuk()
					.then(res => {
						this.echarts8 = res.data;
					})
					.catch(() => {
						this.echarts8 = [];
					});
			},
			// 入驻企业分布 - data
			qylist() {
				var data = {
					name: [],
					value: []
				};
				qylist()
					.then(res => {
						res.data.map(item => {
							data.name.push(item.name);
							data.value.push({
								value: Number(item.num),
								name: item.name
							});
						});
						data.value.push({
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						}, {
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						}, {
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						}, {
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						});
						data = data;
						this.$nextTick(function() {
							this.echarts9(data);
						});
					})
					.catch(() => {
						data = [];
					});
			},
			// 入驻企业分布 - 图表
			echarts9(data) {
				let echarts3 = this.$echarts.init(document.getElementById('echarts9'));
				echarts3.setOption({
					tooltip: {
						trigger: 'item',
						formatter: '{b} : {c} ({d}%)'
					},
					legend: {
						data: data.name,
						icon: 'circle',
						textStyle: {
							color: 'rgba(255,255,255,.6)'
						}
					},
					calculable: true,
					series: [{
						color: ['#62c98d', '#2f89cf', '#4cb9cf', '#50ceff', '#e1cc00', '#6bdd9b'],
						type: 'pie',
						startAngle: 0,
						radius: [51, 100],
						center: ['50%', '45%'],
						roseType: 'area',
						avoidLabelOverlap: false,
						label: {
							normal: {
								show: true
							},
							emphasis: {
								show: true
							}
						},
						labelLine: {
							normal: {
								show: true,
								length2: 1
							},
							emphasis: {
								show: true
							}
						},
						data: [{
								value: 1,
								name: '电子信息'
							},
							{
								value: 4,
								name: '生物医药'
							},
							{
								value: 5,
								name: '装备制造'
							},
							{
								value: 6,
								name: '农产品加工'
							},
							{
								value: 9,
								name: '能源化工'
							},

							{
								value: 10,
								name: '新材料'
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							}
						]
					}]
				});
				window.addEventListener('resize', function() {
					echarts3.resize();
				});
			}
		},
		beforeDestroy() {
			if (this.timer) {
				clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
			}
		}
	};
</script>
<style lang="less" scoped>
	.nav-bar{
		display: none !important;
	}
	footer{
		display: none !important;
	}
	.governmentss {
		max-width: 100%;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		background: #000d4a url('../../../assets/img/images/dapingbga.jpg') no-repeat;
		background-position: center top;
		background-size: 100% 100%;
		overflow: hidden;

		.container {
			width: 100%;

			.header {
				width: 100%;
				height: 110px;
				background: url('../../../assets/img/images/dptop_01.png') no-repeat;
				background-position: center top;
				background-size: 100% 100%;
				position: relative;

				h3 {
					font-size: 38px;
					color: #fff;
					line-height: 100px;
					font-weight: bold;
					text-align: center;
				}

				p {
					position: absolute;
					right: 107px;
					top: 35%;
					font-size: 18px;
					color: #ffffff;
				}
			}

			.main {
				padding: 10px 20px 0;

				.mainCon {
					margin: 0 -0.4rem;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					box-sizing: border-box;

					.box {
						.tabsbga{
							width: 100%;
							height: 357px;
							background: url('../../../assets/img/images/kone_03.png') no-repeat center;
							background-size: 100% 357px;
							overflow: hidden !important;
						}
						.tabsbgb{
							width: 100%;
							height: 308px;
							background: url('../../../assets/img/images/kone_03.png') no-repeat center;
							background-size: 100% 308px;
						}
						.tabsbgc{
							width: 100%;
							height: 295px;
							background: url('../../../assets/img/images/kthere_06.png') no-repeat center;
							background-size: 100% 295px;
						}
						.tabsbgd{
							width: 100%;
							height: 292px;
							background: url('../../../assets/img/images/dpca_03.png') no-repeat center;
							background-size: 100% 292px;
							padding: 12px 30px !important;
							display: flex;
							align-items: center;
							justify-content: space-between;
						}
						.tabsbge{
							width: 100%;
							height: 364px;
							background: url('../../../assets/img/images/dpcb_06.png') no-repeat center;
							background-size: 100% 364px;
							padding: 12px 30px !important;
						}
						.tabsbgf{
							width: 100%;
							height: 302px;
							background: url('../../../assets/img/images/dpcc_09.png') no-repeat center;
							background-size: 100% 302px;
							padding: 12px 30px !important;
						}
						.tabsbgg{
							width: 100%;
							height: 344px;
							background: url('../../../assets/img/images/dpcd_03.png') no-repeat center;
							background-size: 100% 344px;
							padding: 12px 30px !important;
						}
						.tabs {
							padding: 12px;
							margin-bottom: 25px;
							h3 {
								height: 24px;
								padding-left: 25px;
								margin-bottom: 12px;
								font-size: 20px;
								color: #fff;
								line-height: 24px;
								position: relative;

								&:before {
									content: ''; 
									width: 8px;
									height: 12px;
									position: absolute;
									top: 8px;
									left: 5px;
									border-radius: 20px;
									background: url('../../../assets/img/images/title_03.png') no-repeat center;
									background-size: 8px 12px;
								}
							}

							.echartsBox {
								height: calc(100% - 30px);
							}

							&.two {
								.tabTit {
									padding: 30px 17px 10px ;
									margin-bottom: 10px;
									border-bottom: 1px solid rgba(255, 255, 255, 0.2);
									span{
										color: #2f9afe;
										font-size: 16px;
									}
								}

								ul {
									padding: 0 17px 0px ;
									li {
										width: 100%;
										display: flex;
										justify-content: space-between;
										&:nth-child(2n){
											background-color: #102040;
										}
										span {
											display: inline-block;
											font-size: 16px;
											color: rgba(255, 255, 255, 1);
											// text-align: center;
											text-overflow: ellipsis;
											white-space: nowrap;
											overflow: hidden;

											&:nth-child(1){
												width: 20%;
												color: #2f9afe;
											}
											&:nth-child(2) {
												width: 20%;
											}

											&:nth-child(3),
											&:nth-child(4) {
												width: 30%;
											}
										}
									}
								}

								.echartsBox {
									span {
										line-height: 40px;
									}
								}
							}

							.leftPart {
								width: 50%;
								h2 {
									padding-top: 14px;
									font-size: 23px;
									color: #fff;

									i {
										font-size: 18px;
										color: #fff;
									}
								}

								p {
									width: 175px;
									height: 175px;
									background: url('../../../assets/img/images/shuzi_03.png') no-repeat center;
									background-size: cover;
									text-align: center;
									line-height: 175px;
									font-size: 48px;
									color: #ffffff;
									font-weight: bold;
									margin-top: 30px;
								}
							}

							.rightPart {
								width: 100%;
								height: 100%;
								padding-top: 50px;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.governmentss .container .header h3{
			font-size: 1.4rem;
		}
	}
</style>